<script setup>

import {usePage} from "@inertiajs/vue3";

const page = usePage();

</script>

<template>
    <img v-if="page.props.settings.logo" :src="page.props.asset_path+'/'+page.props.settings.logo" alt="Logo"
         class="aspect-auto">
</template>
